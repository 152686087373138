import Footer from "components/global/footer";
import Nav from "components/global/nav";
import "./terms.css";

export function TermsAndConditionsPage() {
  return (
    <div className="terms">
    <Nav />
    <div className="container terms-and-conditions br">
      <div className="gap">
        <h5>TERMS &amp; CONDITIONS</h5>

        <p className="gap2">
          Please Read These Terms and Conditions Carefully Before Usage.
        </p>

        <p>
          By clicking the box that states your acceptance of these terms and
          conditions, you have expressly agreed to these terms and conditions of
          usage. If you do not agree to these terms and conditions, please do
          not use this website as we may suspend or terminate your account and
          block your access to the site for non-compliance with these terms and
          conditions.
        </p>
      </div>

      <div className="gap">
        <h6>1. CREATION OF ACCOUNT</h6>
        <p className="gap2">
          It is expected that a reader and creator (hereinafter referred to as a
          &quot;User&quot;) create an account to participate in activities on
          the app. As a User, you agree to provide and update true, correct
          recent, and complete required information about yourself as a
          registration requirement. A User shall not impersonate any person,
          corporation, or engage in the misrepresentation of identity or
          use/provide another person&#39;s identity information. You agree that
          we may take steps to verify the correctness of information provided by
          you and to promptly notify us via our email and social media platform
          of any breach of security involving your account or that of another.
        </p>
        <p>
          Users are responsible for updating personal information and providing
          us with their current e-mail addresses. In the event that the last
          e-mail address that you provided us is no longer valid, or for any
          other reason is not capable of delivering to you the notice described
          above, our dispatch of the e-mail containing such notice will
          nonetheless constitute effective notice of the changes, modifications,
          additions, or deletions described in the notice.
        </p>
      </div>

      <div className="gap">
        <h6>2. THE CHATROOM</h6>

        <p className="gap2">
          The chatroom is a feature that enables users to communicate and share
          their views. Personal and sensitive information such as telephone,
          e-mail, or home address) should not be disclosed in the chatroom.
          Disclosure of personal information through the chatroom can expose a
          user&#39;s information to be available and used by individuals with
          malign intentions and such disclosure of the information is at your
          own risk as the security or privacy of any information you choose to
          disclose in the chatroom is not guaranteed. You are and shall remain
          solely responsible for the information you make available on and
          through the app.
        </p>
        <p>
          By using the Munkai platform, you acknowledge that the use of any
          information, recommendations, and advice posted in the chatroom are
          done at your own risk as we do not endorse any comments and we
          specifically disclaim any liability in connection therewith.
          Information given in the chatroom may not be reliable, and we strongly
          advise that users should not make any investment decisions based on
          such information. We shall not be liable for the inaccuracy of any
          information contained in a chatroom or any of our platforms that is
          not provided by us.
        </p>
      </div>

      <div className="gap">
        <p className="baloo2">PUBLICATIONS</p>

        <p>
          Without prior notice or consent from any user, it is within our
          discretion to screen, decline, delete and refuse to post any
          submission that fails to comply with these terms and conditions.
          However, if we have questions about your submission(s) including,
          without limitation, the copyright, we may contact you for further
          information including, to verify that you own the copyright or
          otherwise obtained permission before submitting such work.
        </p>
      </div>

      <div className="gap">
        <h6>3. CONFIDENTIALITY OF PERSONAL &amp; ACCOUNT INFORMATION</h6>

        <p>
          Every user is responsible for ensuring the confidentiality of his/her
          username, password, and any other information whether sensitive or
          not, including activities that take place in your account. You hereby
          agree to indemnify, defend, and hold us, our agents, legal
          representative(s), and other authorized users, harmless from and
          against any, and all losses, damages, liabilities, claims, penalties,
          fines, expenses arising out of any breach by you of these terms and
          conditions or the use of this app.
        </p>
      </div>

      <div className="gap">
        <h6>4. RULES OF CONDUCT</h6>

        <p>
          By using this app, you acknowledge and agree not to post or upload on
          the app, any of the following:
        </p>

        <p className="gap1">
          (a) Materials that are defamatory, abusive, harassing, threatening, or
          an invasion of a right of privacy of another person;
        </p>
        <p className="gap1">
          (b) Materials that contain hate speech, racially, religiously
          offensive, and otherwise;
        </p>
        <p className="gap1">
          (c) Materials that portray violence, vulgar, obscene, pornographic, or
          otherwise sexually explicit content;
        </p>
        <p className="gap1">
          (d) Materials that can otherwise harm any person or corporation;
        </p>
        <p className="gap1">
          (e) Materials that promote child pornography, stalking, sexual
          assault, fraud, trafficking, drug dealing and/or drug abuse,
          harassment, theft, or conspiracy to commit any criminal activity;
        </p>
        <p className="gap1">
          (f) Materials that are antisocial, disruptive, or destructive;
        </p>
        <p className="gap1">
          (g) Materials that are illegal or encourages or advocates illegal
          activity;
        </p>
        <p className="gap1">
          (h) Materials that infringes or violates any right of a third party
          such as copyright, patent, trademark, trade secret, or other
          intellectual, proprietary, or contractual rights;
        </p>
        <p className="gap1">
          (i) Materials that infringe the right of privacy of another person or
          user;
        </p>
        <p className="gap1">
          (j) Materials that suggests illegal solicitation of funds from others;
        </p>
        <p className="gap1">
          (k) Materials that contain a virus, malware, worm, trojan, ransomware,
          and other harmful codes and component which tampers with, impair, or
          damages the app and our computer and or otherwise interrupt the use of
          the responsibility platform by other users.
        </p>
      </div>

      <div className="gap">
        <h6>5. TERMINATION</h6>

        <p className="gap2">
          The Terms and Conditions are binding on users until terminated.
        </p>

        <p className="gap1">
          <span>5.1.1.</span> These Terms and Conditions are no longer binding
          once a user terminates the use of MUNKAI and all its platform and
          thereafter closes all his accounts with MUNKAI
        </p>

        <p>
          <span>5.1.2.</span> We shall immediately terminate a user&#39;s
          account including removing access to all Munkai apps without prior
          notice if he/she is found to have breached any of the terms and
          conditions and continuously or persistently engage in the infringement
          of copyrights, involvement in any fraudulent, abusive or illegal
          activity and may be reported to and through an appropriate sanctioning
          body.
        </p>
      </div>

      <div className="gap">
        <h6>6. FEE/COMMISSION</h6>

        <p>Chapter Minting Fee:</p>
        <p className="gap">
          The minting fee goes straight to a dead wallet for burn. The essence
          of this is to enhance the value of the Munk token.
        </p>
        <p>Chapter Purchase Commission:</p>
        <p>
          Munkai takes a 10% commission on payments made by readers to read
          comics on the platform.
        </p>
      </div>

      <div className="gap">
        <h6>7. NO OBLIGATION TO REFUND</h6>

        <p>
          Every and any fee paid by a user are considered non-refundable
          deposits and are therefore not redeemable/refundable by a user or
          Munkai on any grounds whatsoever.
        </p>
      </div>

      <div className="gap">
        <h6>8. GOVERNING LAW AND DISPUTE RESOLUTION</h6>

        <p>
          The validity, construction, and performance of these terms &amp;
          conditions shall be governed and construed in accordance with the laws
          of the Federal Republic of Nigeria. Any dispute shall be settled by a
          sole arbitrator in accordance with the Arbitration and Conciliation
          Act, CapA18, Laws of the Federation of Nigeria, 2004. The place of
          arbitration shall be Lagos State and the language of arbitration shall
          be English.The decision of the arbitrator shall be final and binding
          on the Parties and each Party shall bear his own legal cost and will
          be liable to contribute the same. Every user hereby consents and
          submits to the jurisdiction of this law and arbitration body.
        </p>
      </div>

      <div className="gap">
        <h6>9. SEVERABILITY</h6>

        <p>
          If any part of these terms and conditions is held unenforceable, the
          remainder of it shall continue to be in force and have effect as
          though no part was affected.
        </p>
      </div>

      <div className="gap">
        <h6>10. LIABILITY</h6>

        <p>
          You exempt and hold Munkai unaccountable for any fees or damages
          resulting from a finally sustained lawsuit brought against the use of
          the app due to plagiarism, other theft, a civil or criminal offense in
          Nigeria, your country, or any other part of the world
        </p>
      </div>

      <div className="gap">
        <h6>11. AMENDMENTS</h6>

        <p>
          Without prior notice to you or any user of the Munkai platform(s) and
          at our discretion, we have the power to update, change, modify, add,
          or delete portions of these terms and conditions at any time. In the
          event that we do so, we may or may not notify you of any such change,
          modification, addition, or deletion by sending you an e-mail at the
          last e-mail address that you provided us, and by posting notice of any
          such change, modification, addition, or deletion on our various
          platform. If you do not agree to the above-stated conditions of
          change, you may delete your account now.
        </p>
      </div>

      <div className="gap">
        <h6>12. GENERAL PROVISIONS</h6>

        <p className="gap1">
          This app shall not be used for any unlawful purposes or any purpose
          that will harm another person, user, or corporation.
        </p>
        <p className="gap1">
          No content on this site may be modified, reproduced, transmitted,
          distributed, or otherwise exploited in any way without prior written
          permission from our legal representative(s).
        </p>
        <p className="gap1">
          This platform, app, and content are for your personal use only and not
          for commercial purposes.
        </p>
        <p className="gap1">
          We do not accept contents, suggestions, or materials which are stolen
          or are found to be the intellectual property of another person, user,
          organization, company whether intentionally or unintentionally
          redistributed on our platform.
        </p>
        <p className="gap1">
          You agree that any content submitted by you are original, authentic,
          and solely owned by you.
        </p>
        <p className="gap1">
          You acknowledge and agree that any submission you make is not done in
          confidence or trust and no fiduciary relationship is created between
          you and us in any way.
        </p>
        <p className="gap1">
          When you access the app from any device, your network provider rates
          and fees might apply and you bear responsibility for those charges.
        </p>
        <p className="gap1">
          In an event where a user becomes aware that another user, non-user, or
          corporate entity is using Munkai as a medium to perform fraudulent
          acts or unlawful purposes, the user is under a duty to report to us.
          Following such report, we may, but shall not be obliged to take
          actions as appropriate and within our discretion.
        </p>
        <p className="gap1">
          With or without your consent we may change or update the site or at
          any time delete content, product, or features for any or no reason.
        </p>
        <p className="gap1">
          You agree that you will not disclose or share your account or personal
          information with others and to immediately notify us of any breach
          which involves the security of your account.
        </p>
        <p className="gap1">
          You agree to notify us of any copyright infringement of your
          intellectual property and we will respond and take appropriate
          actions.
        </p>
        <p className="gap1">
          You acknowledge and agree that nothing in these terms and conditions
          shall have the effect of transferring the ownership of any trademarks,
          service marks, trade names, or other proprietary rights in the app or
          content or any part thereof to you or any third party. You undertake
          not to carry out any act or thing which is inconsistent with or which
          is likely in any way to cause prejudice of such title.
        </p>
        <p className="gap1">
          You acknowledge and agree that when using the app, you may be exposed
          to information and materials from foreign sources and cultures and
          that we are not responsible for the inaccuracy, degree of correctness,
          utility safety, or intellectual property rights of or relating to such
          information or material.
        </p>
        <p>
          You acknowledge and agree that we cannot and do not assure that other
          users are or will be complying with the foregoing terms and conditions
          or any other lawful provisions herewith, thereby exposing you to
          submissions that are inaccurate, offensive, indecent, or otherwise
          objectionable. As between you and us, you hereby assume all risk of
          harm resulting from any such lack of compliance.
        </p>
      </div>

      <div>
        <h6>DISCLAIMER</h6>

        <p className="gap1">
          <span>
            THE INFORMATION AND MATERIAL CONTAINED IN THE MUNKAI PLATFORM &amp;
            APP ARE FOR PUBLIC USAGE AND WE GIVE NO REPRESENTATION, WARRANTY IN
            RESPECT OF SUCH INFORMATION AND MATERIALS.
          </span>
        </p>
        <p className="gap1">
          <span>
            FURTHERMORE, WE DO NOT GUARANTEE THAT THE DETAILS CONTAINED IN
            INFORMATION AND MATERIALS ON THE APP ARE ACCURATE, CORRECT,
            COMPLETE, TRUE, OR ARE ERROR-FREE, OR ANY SOFTWARE OR OTHER DEVICE
            THAT PROVIDES SUCH INFORMATION AND MATERIALS AVAILABLE ARE FREE OF
            VIRUSES OR OTHER HARMFUL COMPONENTS.
          </span>
        </p>
        <p className="gap1">
          <span>
            WE DO NOT GUARANTEE OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR
            THE RESULTS OF THE USE OF ANY INFORMATION, MATERIALS, PRODUCTS, OR
            FEATURES CONTAINED ON OR OFFERED, ON THE APP OR MUNKAI PLATFORM IN
            NO EVENT WILL WE BE LIABLE FOR ANY LOSS OR DAMAGE INCLUDING WITHOUT
            LIMITATION, INDIRECT OR CONSEQUENTIAL LOSS OR DAMAGE, OR ANY LOSS OR
            DAMAGE WHATSOEVER ARISING FROM LOSS OF DATA OR PROFITS ARISING OUT
            OF, OR IN CONNECTION WITH, THE USE OF THIS APP.
          </span>
        </p>
        <p className="gap1">
          <span>
            THROUGH OUR WEB APP YOU MAY BE ABLE TO LINK TO OTHER APP WHICH IS
            NOT UNDER THE CONTROL OF MUNKAI. WE HAVE NO CONTROL OVER THE
            CORRECTNESS, ACCURACY OF THAT PLATFORM&#39;S INFORMATION. THE
            INCLUSION OF ANY LINKS DOES NOT NECESSARILY IMPLY A RECOMMENDATION
            OR ENDORSEMENT OF THE VIEWS EMBEDDED WITHIN THEM.
          </span>
        </p>
        <p>
          {" "}
          <span>
            WE SHALL MAKE ALL EFFORTS TO KEEP THE APP FUNCTIONING SMOOTHLY.
            HOWEVER, MUNKAI TAKES NO RESPONSIBILITY FOR, AND WILL NOT BE LIABLE
            FOR, THE APP BEING TEMPORARILY UNAVAILABLE DUE TO TECHNICAL ISSUES
            BEYOND OUR CONTROL.
          </span>
        </p>
      </div>
    </div>
    <Footer />
    </div>
  );
}
